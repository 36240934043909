/* eslint-disable no-console */
import { toUpper } from 'ramda'

// TODO: Add some logging framework and replace these ugly functions...
const apiLogger = (name, method, path, title, payload, payloadTitle, color, backgroundColor) => {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  if (typeof console.groupCollapsed === 'function') {
    console.groupCollapsed(
      `%c ${name} ${title}: %c${toUpper(method)} %c${path} `,
      `color: ${color}; background: ${backgroundColor};`,
      `color: ${color}; font-weight: bold; background: ${backgroundColor};`,
      `color: #000000; font-weight: normal; background: ${backgroundColor};`
    );

    console.log(`%c${payloadTitle}`, `color: ${color}; font-weight: bold;`, payload);

    console.groupEnd()
  }
};

export default function createLogger({ name }) {
  const logRequest = (method, path, params, headers) => {
    const request = {
      method,
      path,
      params,
      headers
    };

    apiLogger(name, method, path, 'request', request, 'request', '#03A9F4', '#EBF9FF')
  };

  const logResponse = (response, method, path) =>
    apiLogger(name, method, path, 'response', response, 'response', '#4CAF50', '#CEF2D7');

  const logError = (error, method, path) =>
    apiLogger(name, method, path, 'failure', error, 'error', '#F20404', '#FFF0EB');

  return {
    request: logRequest,
    response: logResponse,
    error: logError
  }
}
