import fetch from './../lib/fetch-client'
import Config from './../config'

const conf = new Config();
const request = fetch();

export function login({token}) {
    return request({
        method: "POST",
        path: "/login",
        params:{
            token
        },
    }).then(function ({status, headers, body}) {
        const {user, jwt} = body;
        if (status === 200) {
            conf.jwtToken = jwt.token;
            return {user, jwt};
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function forgotPassword(email) {
    return request({
        method: "POST",
        path: "account/ForgotPassword",
        params:{
            email
        },
        headers:{
            'Context-Type': 'text/plain'
        }
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return true;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function resetPassword({email, password, confirmPassword, code}) {
    return request({
        method: "POST",
        path: "account/ResetPassword",
        params: {
            email, 
            password, 
            confirmPassword, 
            code
        },
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return true;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function getFinalizeAccountInfo(email) {
    return request({
        method: "GET",
        path: `account/finalizeAccount/?email=${email}`,
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return body;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function finalizeAccountInfo(data) {
    const {
        first_name,
        last_name,
        email,
        password,
        cell_phone_number: phone_number,
    }
        = data;

    return request({
        method: "POST",
        path: `account/finalizeAccount`,
        params: {
            first_name,
            last_name,
            email,
            password,
            phone_number,
        },
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return body;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}