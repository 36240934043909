import React from 'react';
import {AutoComplete as AntAutoComplete} from 'antd';
import {FormattedMessage} from "react-intl";

function onSelect(value) {
    console.log('onSelect', value);
}

export default class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        let {dataSource} = this.props;
        this.state = {
            dataSource: dataSource,
            initialData: dataSource,
        };
    }
    
    handleSearch = (value) => {
        this.setState({
            dataSource: !value ? [] : [
                value,
                ...this.state.initialData
            ],
        });
    };

    getPlaceholder = () => {
        if(this.props.placeholderLabelKey){
            return <FormattedMessage id={this.props.placeholderLabelKey}/>
        } else {
            return <FormattedMessage id="select"/>
        }
    };

    render() {
        const {dataSource} = this.state;
        const {labelKey, placeholderLabelKey, ...standardProps} = this.props;
        return (
            <div>
                <label>
                    <FormattedMessage id={labelKey}/>
                </label>
                <AntAutoComplete
                    dataSource={dataSource}
                    style={{width: 200}}
                    onSelect={onSelect}
                    onSearch={this.handleSearch}
                    placeholder={this.getPlaceholder()}
                    {...standardProps}
                />
            </div>

        );
    }
}