import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {
    Form, Button, Select, DatePicker, TreeSelect, Col, Row, Modal
} from 'antd';
import moment from 'moment';

import {
    coworkersRetrieved,
    shopsRetrieved,
    assignmentDescriptionsRetrieved,
    assignmentRetrieved, apiCallStarted, apiCallFinished
} from "../../actions";
import {
    getCoworker,
    getShops,
    createAssignment,
    getAssignmentDescriptions,
    updateAssignment
} from "../../Api";
import LabeledInput from "../../components/labeled-input";
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";
import {deleteAssignment} from "../../Api/assignment";

const {RangePicker} = DatePicker;

const dateFormat = 'YYYY-MM-DD';

class AssignmentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            password: "",
            value: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.props.form.validateFields();

        try {
            this.props.onApiCallStarted();

            let coworkers = await getCoworker();

            let filteredCoworkers = filterCoworkers(coworkers);


            this.props.onCoworkersRetrieved(filteredCoworkers);

            let shops = await getShops();
            this.props.onShopsRetrieved(shops);

            this.props.onApiCallFinished();

        } catch (e) {
            this.props.onErrorReceived(e)
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const {isEditMode = false, isSupplier = false, isAdmin = false} = this.props;

        if (!isSupplier && !isAdmin) {
            return;
        }

        let assignmentData = null;
        this.props.form.validateFields((err, values) => {
            if (values.start_date > values.end_date) {
                this.props.form.setFields({
                    end_date: {
                        value: values.end_date,
                        errors: [new Error('Slutdatumet behöver vara samma dag eller efter startdatumet')],
                    }
                });

                err = true;
            }
            
            if (!err) {
                assignmentData = values;
            }
        });

        if (assignmentData != null) {
            let assignmentResult = null;

            try {

                this.props.onApiCallStarted();

                if (isEditMode) {
                    let {assignment_id} = this.props.selectedAssignment;
                    
                    assignmentResult = await updateAssignment({...assignmentData, assignment_id});
                } else {
                    assignmentResult = await createAssignment(assignmentData);
                }

                this.props.onApiCallFinished();

                this.props.onAssignmentRetrieved(assignmentResult);

                try {
                    this.props.closeModal();
                } catch (e) {
                }

            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    renderSubmitButton = () => {
        const {isEditMode = false, isSupplier = false, isAdmin = false} = this.props;

        if (!isSupplier && !isAdmin) {
            return;
        }

        let {selectedAssignment} = this.props;
        console.log()
        const {onApiCallStarted, onApiCallFinished, onAssignmentRetrieved, closeModal} = this.props;


        let messageId = isEditMode ? "save" : "add";
        return (<Form.Item>
            <Row>
                <Col span={6}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        <FormattedMessage id={messageId}/>
                    </Button>
                </Col>
                {isEditMode &&
                <Col span={6} offset={12}>
                    <Button type="danger" className="login-form-button" onClick={(e) => {
                        Modal.confirm({
                            title: 'Ta bort uppdrag',
                            content: 'Är du säker på att du vill ta bort det här uppdraget',
                            okText: 'Ja',
                            okType: 'danger',
                            cancelText: 'Nej',
                            onOk() {
                                onApiCallStarted();
                                closeModal();
                                let {assignment_id} = selectedAssignment;

                                deleteAssignment(assignment_id).then(function (body) {
                                    onApiCallFinished();
                                    onAssignmentRetrieved(body);
                                });
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>
                        <FormattedMessage id="remove"/>
                    </Button>
                </Col>
                }
            </Row>
        </Form.Item>)
    };

    handleSelectChange(value) {
    }

    onChange(date, dateString) {
        console.log(date, dateString);
    }
    
    render() {
        const {isEditMode = false} = this.props;

        const {getFieldDecorator} = this.props.form;
        const {coworkers, selectedAssignment} = this.props;
        const selectedCoworker = selectedAssignment ? selectedAssignment.coworkers.map(c => (c.key)) : [];
        const selectedShops = selectedAssignment ? selectedAssignment.shops.map(shop => ({
            label: shop.shop_name,
            value: shop.shop_id
        })) : [];

        const assignment_description = selectedAssignment ? selectedAssignment.assignment_description : "";
        const start_date = selectedAssignment ? moment.utc(selectedAssignment.start_date) : moment().utc();
        const end_date = selectedAssignment ? moment.utc(selectedAssignment.end_date) : moment().utc();

        return (
            <UserFeedback>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={<FormattedMessage id="coWorkers"/>}>
                        {getFieldDecorator('coWorker', {
                            rules: [
                                {required: true, message: 'Ange en eller flera medarbetare'}],
                            initialValue: selectedCoworker

                        })(
                            <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                placeholder="Välj"
                                onChange={this.handleSelectChange}
                                labelInValue={false}
                            >
                                {coworkers.map(coworker =>
                                    <Select.Option key={coworker.key}>{coworker.name}</Select.Option>
                                )}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="shops"/>}>
                        {getFieldDecorator('shops', {
                            rules: [
                                {required: true, message: 'Ange en eller fler butiker'}],
                            initialValue: selectedShops

                        })(
                            <TreeSelect
                                showSearch
                                style={{width: 300}}
                                treeNodeFilterProp="title"
                                labelInValue={true}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                placeholder="Please select"
                                allowClear
                                treeDefaultExpandAll
                                multiple
                                ref={(input) => {
                                    this.shopComponent = input;
                                }}
                                onChange={(value, label, extra) => {
                                    this.shopComponent.focus()
                                }}
                                treeData={this.props.shops}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="assignment_description"/>}>
                        {getFieldDecorator('assignment_description', {
                            rules: [{required: true, message: 'Ange uppdragsbeskrivning'}],
                            initialValue: assignment_description
                        })(
                            <LabeledInput type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="start_date"/>}>
                        {getFieldDecorator('start_date', {
                            rules: [{required: true, message: 'Ange startdatum'}],
                            initialValue: start_date
                        })(
                            <DatePicker onChange={this.onChange} />
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="end_date"/>}>
                        {getFieldDecorator('end_date', {
                            rules: [{required: true, message: 'Ange slutdatum'}],
                            initialValue: end_date
                        })(
                            <DatePicker onChange={this.onChange} />
                        )}
                    </Form.Item>
                    {this.renderSubmitButton()}
                </Form>
            </UserFeedback>
        );
    }
}

const mapStateToProps = ({coworker, shop, assignment_description, assignment, auth}) => ({
    coworkers: coworker.coworkers,
    shops: shop.shops,
    assignmentDescriptions: assignment_description.assignmentDescriptions,
    selectedAssignment: assignment.selectedAssignment,
    isSupplier: !auth.accessSuppliers && auth.accessCoWorkers,
    isAdmin: auth.accessSuppliers && auth.accessCoWorkers
});

const mapDispatchToActions = {
    onCoworkersRetrieved: coworkersRetrieved,
    onShopsRetrieved: shopsRetrieved,
    onAssignmentDescriptionsRetrieved: assignmentDescriptionsRetrieved,
    onAssignmentRetrieved: assignmentRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

const filterCoworkers = (filteredCoworkers) => {

    const coworkers = filteredCoworkers.filter(
        (coworker) => true
    );
    return coworkers;
};

export default connect(mapStateToProps, mapDispatchToActions)(AssignmentForm);