export const validatePassword = (password) => {
    const errors = [];
    if (password.length < 6) errors.push('passwordTooShort');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('passwordRequiresNonAlphanumeric');
    if (!/\d/.test(password)) errors.push('passwordRequiresDigit');
    if (!/[A-Z]/.test(password)) errors.push('passwordRequiresUpper');
    return errors;
};

export const handleValidationErrors = (errors, onErrorReceived) => {
    if (errors.length > 0) {
        const errorJson = { "": errors };
        onErrorReceived({ message: JSON.stringify(errorJson) });
        return true; // Indicate that validation failed
    }
    return false; // Validation passed
};
