let instance = null;

class Config {
    constructor() {
        if (!instance) {
            instance = this;
        }

        this._jwtToken = '';
        this._user = {
            full_name: "",
            first_name: "",
            last_name: ""
        };

        return instance;
    }

    get jwtToken() {
        if (this._jwtToken == "") {
            let jwt = JSON.parse(sessionStorage.getItem('jwt'));
            if (jwt !== null && jwt != "") {
                this._jwtToken = jwt.token;
            }
        }

        return this._jwtToken;
    }

    set jwtToken(value) {
        this._jwtToken = value;
    }

    get user() {
        if (this._user.first_name == "") {
            let user = JSON.parse(localStorage.getItem('user'));
            this._user = user;
        }
        return this._user;
    }

    set user(user) {
        this._user = user;
    }
}

export default Config;