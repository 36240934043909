import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {unregister} from './registerServiceWorker';
import {IntlProvider} from 'react-intl';
import languages from './intl';
import './scss/_index.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <IntlProvider locale="sv" messages={languages.sv}>
        <BrowserRouter basename={baseUrl}>
            <App/>
        </BrowserRouter>
    </IntlProvider>,
    rootElement);

unregister();
