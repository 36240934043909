import React, {Component} from 'react';
import {Form, Button, Col, Modal,} from 'antd';
import './index.css';
import LabeledInput from './../../components/labeled-input'
import Select from "../../components/select";
import Autocomplete from "../../components/autocomplete-input";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {createSuppliers, getSuppliers, updateSuppliers} from '../../Api/supplier';
import {apiCallFinished, apiCallStarted, supplierSelected, suppliersRetrieved} from "../../actions";
import {Row} from "antd/lib/grid";
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";
import {deleteAssignment, deleteSuppliers} from "../../Api";

class SupplierForm extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleValidationErrors(errors) {
        if (errors.length > 0) {
            const errorJson = { "": errors };
            this.props.onErrorReceived({ message: JSON.stringify(errorJson) });
            return true; // Indicate that validation failed
        }
        return false; // Validation passed
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let supplierData = null;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                supplierData = values;
            }
        });

        let {isEditMode = false} = this.props;
        if (supplierData != null) {
            try {
                this.props.onApiCallStarted();

                let supplierResult = null;
                const errors=[];
                if (supplierData.email !== supplierData.confirmEmail) {
                    errors.push('emailsMismatch');
                }

                if (this.handleValidationErrors(errors)) {
                    return;
                }

                if (isEditMode) {
                    //TODO: Use ID from form?
                    let {key} = this.props.selectedSupplier;

                    supplierResult = await updateSuppliers({...supplierData, key});
                } else {
                    supplierResult = await createSuppliers(supplierData);
                }

                this.props.onApiCallFinished();
                this.props.onSuppliersRetrieved(supplierResult);
                await this.props.closeModal();

            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    buttonText = () => {
        let {isEditMode = false} = this.props;

        let messageId = isEditMode ? "save" : "add";
        return (<FormattedMessage id={messageId} defaultMessage="" values={{
            b: (chunks) => <b>{chunks}</b>, // Example for bold text
        }} />);
    };

    renderSubmitButton = () => {
        const {isEditMode = false} = this.props;

        const {selectedSupplier, onApiCallStarted, onApiCallFinished, closeModal, onSuppliersRetrieved, onSupplierSelected} = this.props;
        let messageId = isEditMode ? "save" : "add";
        return (<Form.Item>
            <Row>
                <Col span={6}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button">
                        {this.buttonText()}
                    </Button>
                </Col>
                {isEditMode &&
                <Col span={6} offset={12}>
                    <Button type="danger" className="login-form-button" onClick={(e) => {
                        Modal.confirm({
                            title: 'Ta bort leverantör',
                            content: 'Är du säker på att du vill ta bort den här leverantören',
                            okText: 'Ja',
                            okType: 'danger',
                            cancelText: 'Nej',
                            onOk() {
                                onApiCallStarted();
                                closeModal();
                                let {key} = selectedSupplier;

                                deleteSuppliers(key).then(function (body) {
                                    onApiCallFinished();
                                    onSuppliersRetrieved(body);
                                    onSupplierSelected(null);
                                    closeModal();
                                });
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>
                        <FormattedMessage id="remove"/>
                    </Button>
                </Col>
                }
            </Row>
        </Form.Item>)
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const selectedSupplier = this.props.selectedSupplier ? this.props.selectedSupplier
            : {
                first_name: "",
                last_name: "",
                email: "",
                cell_phone_number: "",
                identification_level: "",
                company: "",
                contact_person: ""
            };
        return (
            <UserFeedback>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item>
                        {getFieldDecorator('first_name', {
                            rules: [{required: true, message: <FormattedMessage id="provideFirstName"/>}],
                            initialValue: selectedSupplier.first_name
                        })(
                            <LabeledInput labelKey={'first_name'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('last_name', {
                            rules: [{required: true, message: <FormattedMessage id="provideLastName"/>}],
                            initialValue: selectedSupplier.last_name
                        })(
                            <LabeledInput labelKey={'last_name'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{required: true, message: <FormattedMessage id="provideEmail"/>}],
                            initialValue: selectedSupplier.email

                        })(
                            <LabeledInput labelKey={'email'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('confirmEmail', {
                            rules: [{required: true, message: <FormattedMessage id="confirmEmail"/>}],
                            initialValue: selectedSupplier.email
                        })(
                            <LabeledInput labelKey={'confirmEmail'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Row span={24}>
                            <Col span={18}>
                                {getFieldDecorator('company', {
                                    rules: [{required: true, message: <FormattedMessage id="selectOrSupplyCompany"/>}],
                                    initialValue: selectedSupplier.company_id
                                })(
                                    <Autocomplete labelKey={'company'} dataSource={this.props.companies}
                                                  placeholderLabelKey="selectOrSupplyCompany"/>
                                )}
                            </Col>
                            <Col span={6}>
                                <Button type="primary" onClick={this.props.showCompanyModal}>
                                    <FormattedMessage id="newCompany"/>
                                </Button>
                            </Col>
                        </Row>

                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('contact_person',
                            {
                                rules: [{required: true, message: <FormattedMessage id="selectContactPerson"/>}],
                                initialValue:
                                selectedSupplier.contact_person_id
                            }
                        )
                        (
                            <Select labelKey={'contactPersonAtReitan'} selectOptions={this.props.contactPeople}
                                    placeholderLabelKey="selectContactPerson"/>
                        )
                        }
                    </Form.Item>
                    <Form.Item>
                        {this.renderSubmitButton()}
                    </Form.Item>
                </Form>
            </UserFeedback>
        );
    }
}

const mapStateToProps = ({supplier}) => ({
    selectedSupplier: supplier.supplierPage.selectedSupplier,
    companies: supplier.companies,
    contactPeople: supplier.contactPeople,
    identificationRequirementLevels: supplier.identificationRequirementLevels,
});

const mapDispatchToActions = {
    onSupplierSelected:supplierSelected,
    onSuppliersRetrieved: suppliersRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(SupplierForm);