import fetch from './../lib/fetch-client'

const request = fetch();

export function getAssignmentPass(qRCodeData) {
    return request({
        method: "GET",
        path: `assignmentPass/index?assignment_id=${qRCodeData}`,
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function checkInOrOut(qRCodeData, shopNumber) {
    return request({
        method: "GET",
        path: `checkin/index?qRCodeData=${qRCodeData}&shopNumber=${shopNumber}&`,
    }).then(function ({status, headers, body}) {
        return body;
    });
}