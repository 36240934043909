import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container} from 'react-bootstrap';
import {FormattedMessage} from "react-intl";
import {Form, Modal} from 'antd';
import PageHead from '../../components/PageHead';
import AssignmentForm from './assignment-form'
import AssignmentTable from "./assignment-table";
import {Layout} from '../../components/Layout';
import {clearSelectedAssignment, coworkersRetrieved} from "../../actions";

const WrappedAssignmentForm = Form.create({name: 'assignmentForm'})(AssignmentForm);

class AssignmentPage extends Component {
    state = {visible: false};

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
    }

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    showModal = () => {
        this.props.onModalShowed();
        this.setState({
            visible: true,
        });

        if (this.form !== undefined) {
            this.form.resetFields();
        }
    };

    setFormRef = (form) => {
        this.form = form;
    };

    render() {
        const {isSupplier} = this.props;
        const {isAdmin} = this.props;

        return (
            <Layout>
                <Container>
                    <PageHead
                        actionId="newAssignment"
                        headingId="assignments"
                        onActionClick={this.showModal}
                        showAddButton={isSupplier || isAdmin}
                    />

                    <AssignmentTable onFormPosted={this.handleFormPosted} showEditButton={isSupplier || isAdmin}/>

                    <Modal
                        footer={null}
                        title={<FormattedMessage id="newAssignment"/>}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <WrappedAssignmentForm ref={this.setFormRef} onFormPosted={this.handleFormPosted}
                                               closeModal={this.handleCancel} isEditMode={false}/>
                    </Modal>
                </Container>
            </Layout>
        );
    }
}

const mapStateToProps = ({coworker, assignment, auth}) => ({
    coworkers: coworker.coworkers,
    assignments: assignment.assignments,
    isSupplier: !auth.accessSuppliers && auth.accessCoWorkers,
    isAdmin: auth.accessSuppliers && auth.accessCoWorkers
});

const mapDispatchToActions = {
    onCoworkersRetrieved: coworkersRetrieved,
    onModalShowed: clearSelectedAssignment
};

export default connect(mapStateToProps, mapDispatchToActions)(AssignmentPage);
