import fetch from './../lib/fetch-client'

const request = fetch();

export function getShops() {
    return request({
        method: "GET",
        path: "shops",
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function getShopsForCompany(company_id) {
    let path = "shop/CurrentShopsForCompany?companyId="+company_id;
    return request({
        method: "GET",
        path: path ,
    }).then(function ({status, headers, body}) {
        return body;
    });
}