import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
    return (
    <Route
        {...rest}
        render={props => (
            isLoggedIn
                ? <Component {...props} />
                : <Redirect to="/" />
        )}
    />
)};

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({
    isLoggedIn: auth.isLoggedIn});

export default connect(mapStateToProps)(PrivateRoute);