import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Form} from 'antd';
import {Container, Row, Col} from 'react-bootstrap';
import {ReactSVG} from 'react-svg';
import {login} from './../../Api/account';
import LoginForm from "../login/login-form";
import styles from './LoginPage.module.scss';
import './../../actions/'
import {apiCallFinished, apiCallStarted, loginInitiated, loginSuccessful} from "../../actions";
import {Redirect} from 'react-router-dom';
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";

//firebase related imports
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './../../firebase-config';

const WrappedLoginForm = Form.create({name: 'login'})(LoginForm);

class LoginPage extends Component {
    state = {
        redirect: false,
        consentText: ''
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // Access `intl` and set consentText once the component is mounted
        const { intl } = this.props;
        //pinging backend
        const BASE_URL = process.env.REACT_APP_BACKEND_DOMAIN;
        fetch(`${BASE_URL}/health-check`)
        .then(response => response.json())
        .then(data => console.log("Backend is awake:", data))
        .catch(error => console.error("Error pinging backend:", error));

        const consentText = intl.formatMessage({ id: "consentInformation" });
        this.setState({ consentText });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        let {userId, password, remember} = this.form.getFieldsValue();
        this.props.onLoginInitiated({userId, password, remember});
        try {
            this.props.onApiCallStarted();
    
            const userCredential = await signInWithEmailAndPassword(auth, userId, password);
            const { user } = userCredential;
            const idToken = await user.getIdToken(true);
            let loginResponse = await login({ token: idToken }); 
            this.props.onApiCallFinished();
            this.props.onLoginSuccessful(loginResponse);
            this.setState({
                redirect: true
            });
        }  catch (e) {
            this.props.onErrorReceived(e)
        }
    }

    setFormRef = (form) => {
        this.form = form;
    };

    renderRedirect = () => {
        if (this.props.auth.isLoggedIn) {
            return <Redirect to='/assignments'/>
        }
    };

    render() {
        const {isLoggingIn} = this.props;
        const {credentials} = this.props.auth;
        const { consentText } = this.state;
        return (
            <Container>
                <UserFeedback>
                    <Row>
                        <Col xs={8} className="mx-auto">
                            <Row className={styles.head}>
                                <ReactSVG src="/img/logotype.svg" className="m-auto"/>
                            </Row>

                            <Row className={styles.form}>
                                <Col xs={9} className="mx-auto">
                                    {this.renderRedirect()}
                                    <h1 className={styles.heading}>
                                        <FormattedMessage id="logIn"/>
                                    </h1>
                                    {isLoggingIn &&
                                    <p>Logging in</p>
                                    }
                                    <WrappedLoginForm ref={this.setFormRef} onSubmit={this.handleSubmit}
                                        credentials={credentials} />
                                    <i id="consentInformation" dangerouslySetInnerHTML={{ __html: consentText }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </UserFeedback>
            </Container>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToActions = {
    onLoginInitiated: loginInitiated,
    onLoginSuccessful: loginSuccessful,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(injectIntl(LoginPage));//wrapped with injectIntl in order to use intl props