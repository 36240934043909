import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Row, Col} from 'react-bootstrap';
import {Form, Modal} from 'antd';
import {FormattedMessage} from "react-intl";
import SupplierForm from './supplier-form'
import SupplierTable from "./supplier-table";
import {Layout} from '../../components/Layout';
import PageHead from '../../components/PageHead';
import {getSuppliers, getCompanies, getContactPeople, getIdentificationLevelRequirements} from './../../Api/supplier';
import {
    suppliersRetrieved,
    companiesRetrieved,
    clearSupplier,
    contactPeopleRetrieved,
    identificationRequirementLevelRetrieved, shopsRetrieved, apiCallStarted, apiCallFinished
} from "../../actions";
import CompanyForm from "../companies/company-form";
import {getShops, login} from "../../Api";
import ErrorModal from "../../components/error-modal";
import {errorReceived} from "../../actions/user-feedback";

const WrappedSupplierForm = Form.create({name: 'supplier'})(SupplierForm);
const WrappedCompanyForm = Form.create({name: 'companies'})(CompanyForm);


class SupplierPage extends Component {
    state = {visible: false};

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let contactPeople = await getContactPeople();
        this.props.onContactPeopleRetrieved(contactPeople);

        let suppliers = await getSuppliers();
        this.props.onSuppliersRetrieved(suppliers);

        let companies = await getCompanies();
        this.props.onCompaniesRetrieved(companies);

        let levelRequirements = await getIdentificationLevelRequirements();
        this.props.onIdentificationRequirementLevelRetrieved(levelRequirements);

        let shops = await getShops();
        this.props.onShopsRetrieved(shops);
    }

    showSupplierModal = () => {
        this.setState({
            supplierModalVisible: true,
        });

        this.props.onModalShowed();
    };

    closeSupplierModal = () => {
        this.setState({
            supplierModalVisible: false,
        });
    };

    showCompanyModal = () => {
        this.setState({
            companyModalVisible: true,
        });

        //this.props.onModalShowed();
    };

    closeCompanyModal = (e) => {
        this.setState({
            companyModalVisible: false,
        });
    };

    handleCompanyOk = (e) => {
        this.setState({
            companyModalVisible: false,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            supplierModalVisible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            supplierModalVisible: false,
        });
    };

    handleCompaniesCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleFormPosted = async () => {
            let suppliers = await getSuppliers();
            this.props.onSuppliersRetrieved(suppliers);
            this.closeSupplierModal(null);
    };

    handleCompaniesPosted = async () => {
        let companies = await getCompanies();
        this.props.onCompaniesRetrieved(companies);
    };

    render() {
        return (
            <Layout>
                <Container>
                    <PageHead
                        actionId="newSupplier"
                        headingId="suppliers"
                        onActionClick={this.showSupplierModal}
                        showAddButton={true}
                    />

                    <SupplierTable onFormPosted={this.handleFormPosted} closeCompanyModal={this.closeCompanyModal}
                                   showCompanyModal={this.showCompanyModal}/>

                    <Modal
                        footer={null}
                        title={<FormattedMessage id="newSupplier" />}
                        visible={this.state.supplierModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                    >
                        <WrappedSupplierForm onFormPosted={this.handleFormPosted}
                                             closeSupplierModal={this.closeSupplierModal}
                                             closeModal={this.closeSupplierModal}
                                             showCompanyModal={this.showCompanyModal}/>
                    </Modal>

                    <Modal
                        footer={null}
                        title={<FormattedMessage id="newCompany"/>}
                        visible={this.state.companyModalVisible}
                        onOk={this.handleCompanyOk}
                        onCancel={this.closeCompanyModal}
                    >
                        <WrappedCompanyForm onFormPosted={this.handleCompaniesPosted}
                                            closeModal={this.closeCompanyModal}/>
                    </Modal>
                </Container>
            </Layout>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({supplier}) => ({supplier});
const mapDispatchToActions = {
    onModalShowed: clearSupplier,
    onSuppliersRetrieved: suppliersRetrieved,
    onCompaniesRetrieved: companiesRetrieved,
    onContactPeopleRetrieved: contactPeopleRetrieved,
    onIdentificationRequirementLevelRetrieved: identificationRequirementLevelRetrieved,
    onShopsRetrieved: shopsRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(SupplierPage);
