import {Upload, Icon, message, Form} from 'antd';
import * as React from "react";
import {FormattedMessage} from "react-intl";

const BASE_URL = process.env.REACT_APP_BACKEND_DOMAIN;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
        message.error('Image must smaller than 4MB!');
    }
    return isJpgOrPng && isLt2M;
}

export class AvatarUpload extends React.Component {
    state = {
        loading: false,
    };

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({loading: true});
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    render() {
        const uploadButton = (


            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'}/>
                <div className="ant-upload-text">
                    {this.state.loading ?
                        <FormattedMessage id="uploading"/>
                        : <FormattedMessage id="upload"/>
                    }
                </div>
            </div>
        );
        const {imageUrl} = this.state;
        return (
            <div>
                <FormattedMessage id="uploadPhoto"/>
                <Upload
                    name="avatar"
                    accept=".jpg,.png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`${BASE_URL}/account/UploadAvatar?email=${this.props.email}`}
                    beforeUpload={beforeUpload}
                    onChange={this.handleChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                </Upload>
            </div>
        );
    }
}