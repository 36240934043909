import fetch from './../lib/fetch-client'

const request = fetch();

export function getAssignment() {
    return request({
        method: "GET",
        path: "assignments",
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function createAssignment(data) {
    let {coWorker, start_date, end_date, shops, assignment_description} = data;
    let storeGuids = shops.map(store => store.value);

    start_date = start_date.utc().format('YYYY-MM-DD');
    end_date = end_date.utc().format('YYYY-MM-DD');
    return request({
        method: "POST",
        path: "assignments",
        params: {
            coworkers: coWorker,
            shops: storeGuids,
            assignment_description,
            start_date,
            end_date

        }
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function updateAssignment(data) {
    let {assignment_id, coWorker, start_date, end_date, shops, assignment_description} = data;

    let storeGuids = shops.map(store => store.value);
    
    start_date = start_date.utc().format('YYYY-MM-DD');
    end_date = end_date.utc().format('YYYY-MM-DD');
    
    return request({
        method: "PATCH",
        path: "assignment",
        params: {
            assignment_id,
            coworkers: coWorker,
            shops: storeGuids,
            assignment_description,
            start_date,
            end_date
        }
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function deleteAssignment(id) {
    return request({
        method: "DELETE",
        path: `assignment/${id}`,
    }).then(function ({status, headers, body}) {
        return body;
    });
}
