import {ASSIGNMENT_ACTION_TYPE} from './constants'

export const assignmentRetrieved = (assignments) => ({
   type: ASSIGNMENT_ACTION_TYPE.ASSIGNMENT_RETRIEVED,
   payload: {
      assignments
   }
});

export const assignmentSelected = (assignment) => ({
   type: ASSIGNMENT_ACTION_TYPE.ASSIGNMENT_SELECTED,
   payload: {
      assignment
   }
});

export const clearSelectedAssignment = () => ({
   type: ASSIGNMENT_ACTION_TYPE.CLEAR_ASSIGNMENT,
   payload: {}
});