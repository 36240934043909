import {SUPPLIER_ACTION_TYPE} from './constants'

export const supplierSelected = (supplier) => ({
   type: SUPPLIER_ACTION_TYPE.SUPPLIER_SELECTED,
   payload: {
      supplier
   }
});
export const clearSupplier = () => ({
   type: SUPPLIER_ACTION_TYPE.CLEAR_SUPPLIER,
   payload: {
   }
});


export const suppliersRetrieved = (suppliers) => ({
   type: SUPPLIER_ACTION_TYPE.SUPPLIERS_RETRIEVED,
   payload: {
      suppliers
   }
});

export const companiesRetrieved = (companies) => ({
   type: SUPPLIER_ACTION_TYPE.COMPANIES_RETRIEVED,
   payload: {
      companies
   }
});

export const companySelected = (company) => ({
   type: SUPPLIER_ACTION_TYPE.COMPANY_SELECTED,
   payload: {
      company
   }
});

export const contactPeopleRetrieved = (contactPeople) => ({
   type: SUPPLIER_ACTION_TYPE.CONTACT_PEOPLE_RETRIEVED,
   payload: {
      contactPeople
   }
});

export const identificationRequirementLevelRetrieved = (identificationRequirementLevels) => ({
   type: SUPPLIER_ACTION_TYPE.IDENTIFICATION_REQUIREMENT_LEVEL_RETRIEVED,
   payload: {
      identificationRequirementLevels
   }
});
