import React, {Component} from 'react';
import {connect} from "react-redux";
import {Container, Row, Col} from 'react-bootstrap';
import {Form, Modal} from 'antd';
import {FormattedMessage} from "react-intl";
import CompanyForm from "../companies/company-form";
import CompanyTable from "./company-table";
import {Layout} from '../../components/Layout';
import PageHead from '../../components/PageHead';
import {getSuppliers, getCompanies, getContactPeople, getIdentificationLevelRequirements} from './../../Api/supplier';
import {
    suppliersRetrieved,
    companiesRetrieved,
    clearSupplier,
    contactPeopleRetrieved,
    identificationRequirementLevelRetrieved, shopsRetrieved, companySelected
} from "../../actions";
import {getShops} from "../../Api";

const WrappedCompanyForm = Form.create({name: 'companies'})(CompanyForm);


class CompanyPage extends Component {
    state = {visible: false};

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let companies = await getCompanies();
        this.props.onCompaniesRetrieved(companies);

        let levelRequirements = await getIdentificationLevelRequirements();
        this.props.onIdentificationRequirementLevelRetrieved(levelRequirements);

        let shops = await getShops();
        this.props.onShopsRetrieved(shops);
    }

    showSupplierModal = () => {
        this.setState({
            companyModalVisible: true,
        });

        this.props.onModalShowed();
    };

    closeSupplierModal = () => {
        this.setState({
            companyModalVisible: false,
        });
    };

    showCompanyModal = () => {
        this.props.onCompanySelected(null);

        this.setState({
            companyModalVisible: true,
            isEditMode: false,
        });

        this.props.onModalShowed();
    };

    showEditCompanyModal = () => {
        this.setState({
            companyModalVisible: true,
            isEditMode: true,
        });

        this.props.onModalShowed();
    };
    
    closeCompanyModal = (e) => {
        this.setState({
            companyModalVisible: false,
        });
    };

    handleCompanyOk = (e) => {
        this.setState({
            companyModalVisible: false,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            companyModalVisible: false,
        });
    };

    handleCancel = (e) => {
        this.setState({
            companyModalVisible: false,
        });
    };

    handleCompaniesCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleFormPosted = async () => {
        let suppliers = await getSuppliers();
        this.props.onSuppliersRetrieved(suppliers);
        this.closeSupplierModal(null);
    };

    handleCompaniesPosted = async () => {
        let companies = await getCompanies();
        this.props.onCompaniesRetrieved(companies);
    };

    render() {
        return (
            <Layout>
                <Container>
                    <PageHead
                        actionId="newCompany"
                        headingId="companies"
                        onActionClick={this.showCompanyModal}
                        showAddButton={true}
                    />

                    <CompanyTable onFormPosted={this.handleFormPosted} closeCompanyModal={this.closeCompanyModal}
                                  showCompanyModal={this.showEditCompanyModal}/>

                    <Modal
                        footer={null}
                        title={<FormattedMessage id="newCompany"/>}
                        visible={this.state.companyModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <WrappedCompanyForm isEditMode={this.state.isEditMode} onFormPosted={this.handleFormPosted}
                                             closeSupplierModal={this.closeSupplierModal}
                                             closeModal={this.closeSupplierModal}
                                             showCompanyModal={this.showCompanyModal}/>
                    </Modal>
                </Container>
            </Layout>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({supplier}) => ({supplier});
const mapDispatchToActions = {
    onModalShowed: clearSupplier,
    onSuppliersRetrieved: suppliersRetrieved,
    onCompaniesRetrieved: companiesRetrieved,
    onContactPeopleRetrieved: contactPeopleRetrieved,
    onIdentificationRequirementLevelRetrieved: identificationRequirementLevelRetrieved,
    onShopsRetrieved: shopsRetrieved,
    onCompanySelected: companySelected
};

export default connect(mapStateToProps, mapDispatchToActions)(CompanyPage);
