import { IntlProvider } from 'react-intl';
import svMessages from './lang/common.json'; // Your translation files
import accountMessages from './lang/account.json';
import errorMessages from './lang/error-messages.json'; // Ensure the file extension is correct

const messages = {
    sv: {
        ...svMessages,
        ...accountMessages,
        ...errorMessages,
    }
};

export default messages;
