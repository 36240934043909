import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import styles from './VisitorPass.module.scss'
import {getAssignmentPass, checkInOrOut} from "../../Api";
import ShowListItem from "../../components/show-list-item/ShowListItem";


class CheckingPage extends Component {
    state = {
        assignmentPass: {
            shop_name: "",
            start_date: "",
            end_date: "",
            assignment_description: "",
            qrCodePath: ""
        }
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const {qRCodeData, shop_id} = this.props.match.params;
        let assignmentPass = await getAssignmentPass(qRCodeData);
        await checkInOrOut(qRCodeData, shop_id);
        this.setState({assignmentPass: assignmentPass});
    }

    handleChange(event) {
    }

    async handleSubmit(event) {
    }

    render() {
        const {assignmentPass} = this.state;

        return (
            <Container>
                <input type="button" className="button" onClick={function () {
                    window.external.CancelEvent();
                }}
                       name="tctcancel" value="Avbryt"/>
                <ShowListItem
                    heading={assignmentPass.shop_name}
                    rows={[
                        [
                            {
                                label: 'Start- & Slutdatum',
                                value: `${assignmentPass.start_date} - ${assignmentPass.end_date}`
                            },
                        ],
                        [
                            {
                                label: 'Uppdragsbeskrivning',
                                value: assignmentPass.assignmentDescription
                            },
                            {
                                label: 'Kontaktperson på reitan',
                                value: assignmentPass.reitanContactPerson
                            }
                        ],
                        [
                            {
                                label: 'Identifieringsnivå',
                                value: assignmentPass.identification_requirement_level_string
                            },
                            {
                                label: 'Id',
                                value: assignmentPass.lisenceId
                            }
                        ],
                        [
                            {
                                label: 'Telefonnummer',
                                value: assignmentPass.cell_phone_number
                            }
                        ]
                    ]}
                />
            </Container>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({auth});

export default connect(mapStateToProps)(CheckingPage);