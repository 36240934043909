import fetch from './../lib/fetch-client'

const request = fetch();

export function getCoworker() {
    return request({
        method: "GET",
        path: "users/Coworker",
    }).then(function({ status, headers, body }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        
        return body;
    });
}

export function createCoworker({ first_name, email, cell_phone_number, company_id }) {
    return request({
        method: "POST",
        path: "users/Coworker",
        params: {
            first_name,
            email,
            phone_number: cell_phone_number,
            company_id,
        }
    }).then(function({ status, headers, body: coworkers }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        
        return coworkers;
    });
}

export function updateCoworker({ key, first_name, email, confirm_email, cell_phone_number, confirm_cell_phone_number }) {
    return request({
        method: "PATCH",
        path: "users/Coworker",
        params: {
            key,
            first_name,
            email,
            confirm_email,
            phone_number: cell_phone_number,
            confirm_mobile_phone_number: confirm_cell_phone_number
        },
    }).then(function({ status, headers, body: coworkers }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        
        return coworkers;
    });
}

export function deleteCoworker(coworkerId) {
    return request({
        method: "DELETE",
        path: "users/Coworker?id="+coworkerId,
    }).then(function({ status, headers, body: coworkers }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return coworkers;
    });
}