import {SHOP_ACTION_TYPE} from './constants'

export const shopsRetrieved = (shops) => ({
   type: SHOP_ACTION_TYPE.SHOPS_RETRIEVED,
   payload: {
      shops
   }
});

export const shopsForCompanyRetrieved = (shops) => ({
   type: SHOP_ACTION_TYPE.SHOPS_FOR_COMPANY_RETRIEVED,
   payload: {
      shops
   }
});

