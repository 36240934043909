import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Modal} from 'antd';
import {FormattedMessage} from "react-intl";
import CoworkerForm from './coworker-form';
import CoworkerTable from "./coworker-table";
import {apiCallFinished, apiCallStarted, clearCoworkers, coworkersRetrieved} from "../../actions";
import {Layout} from '../../components/Layout';
import PageHead from '../../components/PageHead';
import { createCoworker, getCoworker, updateCoworker, getCoworkerTable} from './../../Api/coworker';
import {Container, Row, Col} from 'react-bootstrap';
import {errorReceived} from "../../actions/user-feedback";
import {getCompanies} from './../../Api/supplier';

const WrappedCoworkerForm = Form.create({name: 'coworkerForm'})(CoworkerForm);

class CoworkersPage extends Component {
    state = {
        visible: false,
        companies: []
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let coworkers = await getCoworker();
        let coworkersLabelled = labelEmailConfirmedCoworkers(coworkers);
        this.props.onCoworkersRetrieved(coworkersLabelled);

        if (this.props.isAdmin) {
            let companies = await getCompanies();
            this.setState({ companies });
        }
    }

    showModal = () => {
        this.props.onModalShowed();
        this.setState({
            visible: true,
        });
    };

    closeModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleValidationErrors(errors) {
        if (errors.length > 0) {
            const errorJson = { "": errors };
            this.props.onErrorReceived({ message: JSON.stringify(errorJson) });
            return true; // Indicate that validation failed
        }
        return false; // Validation passed
    }

    handleFormSubmit = async (e, form, isEditMode, closeModal) => {
        e.preventDefault();
        const { isSupplier, currentUserCompanyId } = this.props;

        let userData = null;
        form.validateFields((err, values) => {
            if (!err) {
                userData = values;
            } else {
            }
        });

        if (userData != null) {
            let coworkerResult = null;

            try {
                this.props.onApiCallStarted();
                //if supplier add the current company id and send to backend in createCoworker method
                if (isSupplier) {
                    userData = { ...userData, company_id: currentUserCompanyId };
                }
                
                const errors = [];
                if (userData.first_name && (userData.first_name.length < 3 || userData.first_name.length > 40)) {
                    errors.push('requiredStringBetween3and40');
                }

                const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                if (userData.email !== userData.confirmEmail) {
                    errors.push('emailsMismatch');
                }
                if (!userData.email || !new RegExp(emailRegex).test(userData.email)) {
                    errors.push('invalidEmail');
                }
                if (userData.cell_phone_number !== userData.confirm_cell_phone_number) {
                    errors.push('phoneNumberMismatch');
                }

                if (this.handleValidationErrors(errors)) {
                    return;
                }


                if (isEditMode) {
                    let {key} = this.props.selectedCoworker;
                    coworkerResult = await updateCoworker({...userData, key});
                } else {
                    coworkerResult = await createCoworker(userData);
                }

                this.props.onApiCallFinished();

                this.props.onCoworkersRetrieved(coworkerResult);
                this.closeModal();

                form.resetFields();

                try {
                    //Close any other modals we might want to when this call finishes
                    closeModal();
                } catch (e) {
                }

            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    render() {
        const {isSupplier} = this.props;
        const {isAdmin} = this.props;
        return (
            <Layout>
                <Container>
                    <PageHead
                        actionId="newCoworker"
                        headingId="coWorkers"
                        onActionClick={this.showModal}
                        showAddButton={isSupplier || isAdmin}
                    />

                    <CoworkerTable onSubmit={this.handleFormSubmit} onFormPosted={this.handleFormSubmit}/>

                    <Modal
                        footer={null}
                        title={<FormattedMessage id="newCoworker"/>}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <WrappedCoworkerForm onFormPosted={this.handleFormSubmit} companies={this.state.companies} isAdmin={this.props.isAdmin} />
                    </Modal>
                </Container>
            </Layout>
        );
    }
}

const mapStateToProps = ({coworker, auth}) => ({
    coworker,
    isSupplier: !auth.accessSuppliers && auth.accessCoWorkers,
    isAdmin: auth.accessSuppliers && auth.accessCoWorkers,
    selectedCoworker: coworker.selectedCoworker,
    currentUserCompanyId: auth.current_user_company_id,
});

const mapDispatchToActions = {
    onCoworkersRetrieved: coworkersRetrieved,
    onModalShowed: clearCoworkers,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

const labelEmailConfirmedCoworkers = (coworkers) => {

    coworkers.forEach((coworker) => {
        if (coworker.emailConfirmed) {
            coworker.emailConfirmed = "Ja";
        }
        else { coworker.emailConfirmed = "Nej"; }
    });
    return coworkers;
};

export default connect(mapStateToProps, mapDispatchToActions)(CoworkersPage);
