import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import styles from './VisitorPass.module.scss'
import {getAssignmentPass} from "../../Api";
import UserFeedback from "../../components/user-feedback";
import GoogleMapReact from "google-map-react";
import {Icon} from "antd";


class AssignmentPassPage extends Component {
    state = {
        assignmentPass: {
            shop_name: "",
            start_date: "",
            end_date: "",
            assignment_description: "",
            qrCodePath: ""
        }, mapsToShowIndexes: []

    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const {qRCodeData} = this.props.match.params;
        let assignmentPass = await getAssignmentPass(qRCodeData);
        this.setState({assignmentPass: assignmentPass});
    }

    renderShopsName = (shops) => {
        if(!shops)
            return;

        const [firstShop] = shops;
        const remainingShopCount = shops.length - 1;
        if (remainingShopCount > 0) {
            return `${firstShop.shop_name} + ${remainingShopCount} till`;
        }

        return firstShop.shop_name
    };

    handleShopMapClick = i => {
        const { mapsToShowIndexes } = this.state;
        let uppdatedIndexes = [...mapsToShowIndexes]; 
        if (!uppdatedIndexes.includes(i)) {
            uppdatedIndexes.push(i);
        } else {
            uppdatedIndexes = uppdatedIndexes.filter(e => e !== i)
        }
        this.setState({ mapsToShowIndexes: uppdatedIndexes });
    }

    renderShopLocation = (shop) => {
        if (shop.latitude === 0 || shop.longitude === 0) {
            return <div>{shop.shop_name}</div>
        }

        return (<div className={styles.mapContainer}>
            <div className={styles.map}>
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{
                        key: "AIzaSyAlXVX0E8jltlpkArULbk-j_B97iqvPRFM",
                        language: 'sv'
                    }}
                    center={{
                        lat: shop.latitude,
                        lng: shop.longitude
                    }}
                    defaultZoom={16}
                >
                    <Icon type="environment" height="100px" lat={shop.latitude} lng={shop.longitude}/>
                </GoogleMapReact>
            </div>
            <Row>
                <div className={styles.shopNameMap}>{shop.shop_name}</div>
            </Row>
        </div>)
}

    renderShopToggleLocation = (shop, i) => {
        if (shop.latitude === 0 || shop.longitude === 0) {
            return <div className={styles.shopNameMap}>{shop.shop_name}</div>
        }

        const showMap = this.state.mapsToShowIndexes.includes(i);

        return (
            showMap
                ? <div className={styles.mapContainer}>
                    <div className={styles.map}>
                        <GoogleMapReact
                            yesIWantToUseGoogleMapApiInternals
                            bootstrapURLKeys={{
                                key: "AIzaSyAlXVX0E8jltlpkArULbk-j_B97iqvPRFM",
                                language: 'sv'
                            }}
                            center={{
                                lat: shop.latitude,
                                lng: shop.longitude
                            }}
                            defaultZoom={16}
                        >
                            <Icon type="environment" height="100px" lat={shop.latitude} lng={shop.longitude} />
                        </GoogleMapReact>
                    </div>
                    <Row>
                        <div className={styles.shopNameMap}>{shop.shop_name}</div>
                    </Row>
                </div>
                : <Row>
                    <div onClick={() => this.handleShopMapClick(i)} className={`${styles.shopNameMap} ${styles.cursor}`}>+ {shop.shop_name}</div>
                </Row>)
    };

    render() {
        const {assignmentPass} = this.state;
    
        return (
            <UserFeedback>
                <div className={styles.form}>
                    <div className={styles.cutOut}></div>

                    <Row className={styles.heading}>
                        <Col>{this.renderShopsName(assignmentPass.shops)}</Col>
                    </Row>
                    <Row className={styles.sectionTitle}>
                        <Col>Startdatum</Col>
                        <Col>Slutdatum</Col>
                    </Row>
                    <Row className={styles.sectionValue}>
                        <Col>{assignmentPass.start_date}</Col>
                        <Col>{assignmentPass.end_date}</Col>
                    </Row>
                    <Row className={styles.sectionTitle}>
                        <Col>Uppdragsbeskrivning</Col>
                    </Row>
                    <Row className={styles.sectionValue}>
                        <Col>{assignmentPass.assignment_description}</Col>
                    </Row>
                    <Row className={styles.qrCode}>
                        <img src={assignmentPass.qrCodePath}></img>
                    </Row>
                </div>
                {assignmentPass.shops && 
                <div>
                    <Row className={styles.storesHeading}>
                        <Col>Butiker</Col>
                    </Row>
                    {assignmentPass.shops.map((shop, i) =>
                        <div>
                            {assignmentPass.shops.length >= 10 
                            ? this.renderShopToggleLocation(shop, i)
                            : this.renderShopLocation(shop)}
                        </div>
                    )}
                </div>}
            </UserFeedback>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({auth});

export default connect(mapStateToProps)(AssignmentPassPage);