import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import {
    Table, Input, Button, Icon, Row, Col, Modal, Form, Select as AntSelect
} from 'antd';
import GoogleMapReact from 'google-map-react';
import {FormattedMessage} from "react-intl";
import Highlighter from 'react-highlight-words';

import {
    apiCallFinished,
    apiCallStarted,
    assignmentRetrieved,
    assignmentSelected,
    errorReceived,
} from './../../actions'
import AssignmentForm from "./assignment-form";
import {getAssignment} from "../../Api";
import showListItemStyles from '../../components/show-list-item/ShowListItem.module.scss';
import ShowListItem from "../../components/show-list-item/ShowListItem";

const WrappedAssignmentForm = Form.create({name: 'assignmentForm'})(AssignmentForm);

class AssignmentTable extends React.Component {

    state = {
        searchText: '',
        selectedElement: {},
        currentIndex: 0,
        mapsToShowIndexes: []
    };

    async componentDidMount() {
        
        try{
            this.props.onApiCallStarted();
            let assignments = await getAssignment();
            this.props.onApiCallFinished();

            this.props.onAssignmentRetrieved(assignments);
        } catch(e){
            this.props.onErrorReceived(e);
        }

    }

    showModal = () => {
        if (this.form !== undefined) {
            this.form.resetFields();
        }

        this.setState({
            visible: true,
            mapsToShowIndexes: []
        });
    };


    showEditButtonIfPossible = () => {
        const {status} = this.props.selectedAssignment;
        let {showEditButton = false} = this.props;
        
        if (status !== 1 || !showEditButton)
            return undefined;
        
        return this.showModal;
    };

    closeModal = () => {
        this.setState({
            visible: false,
            mapsToShowIndexes: []
        });
        this.props.onAssignmentSelected(null);
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.closeModal();
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys, selectedKeys, confirm, clearFilters,
                         }) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder="Sök"
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Sök
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{width: 90}}
                >
                    Nollställ
                </Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) => {
            switch (dataIndex) {
                case 'start_date':
                    // Convert start_date to string and filter
                    return record.start_date.toString().toLowerCase().includes(value.toLowerCase());
                case 'shops':
                    return record.shops.some(shop => shop.shop_name.toLowerCase().includes(value.toLowerCase()));
                case 'coworkers':
                    // Search within coworker's name, makes use of .some method to see if at least one coworker has matching name
                    return record.coworkers.some(coworker => coworker.name.toLowerCase().includes(value.toLowerCase()));
                //more cases if needed
                default:
                    return false;
            }
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({searchText: selectedKeys[0]});
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText: ''});
    };

    itemSelected = (event, record, rowIndex) => {
        if (this.form !== undefined) {
            this.form.resetFields();
        }

        this.sate = {...this.sate, currentIndex: rowIndex};
        this.props.onAssignmentSelected(record);
    };

    isEditMode = () => {
        return true;
    };

    modalTitle = () => {
        if (this.isEditMode()) {
            return <FormattedMessage id="editAssignment"/>
        } else {
            return <FormattedMessage id="editAssignment"/>
        }
    };

    setFormRef = (form) => {
        this.form = form;
    };

    renderStatusSection = () => {
        const {status} = this.props.selectedAssignment;
        if(status === 2)
            return "Besöket har påbörjats"
    };

    onPrevious = () => {
        let newIndex = this.sate.currentIndex - 1;
        if (newIndex < 0) {
            newIndex = this.props.assignments.length - 1;
        }

        this.sate = {...this.sate, currentIndex: newIndex};

        let prevRecord = this.props.assignments[newIndex];
        this.props.onAssignmentSelected(prevRecord);
    };

    onNext = () => {
        let newIndex = this.sate.currentIndex + 1;
        if (newIndex > this.props.assignments.length - 1) {
            newIndex = 0;
        }

        this.sate = {...this.sate, currentIndex: newIndex};

        let prevRecord = this.props.assignments[newIndex];
        this.props.onAssignmentSelected(prevRecord);
    };

    renderShopsName = (text, record, index) => {
        const {shops} = record;

        const [firstShop] = shops;
        const remainingShopCount = shops.length - 1;
        if (remainingShopCount > 0) {
            return `${firstShop.shop_name} + ${remainingShopCount} till`;
        }

        return firstShop.shop_name
    };

    renderCoworkersName = (text, record, index) => {
        const {coworkers} = record;

        const [firstCoworker] = coworkers;
        const remainingCoworkerCount = coworkers.length - 1;
        if (remainingCoworkerCount > 0) {
            return `${firstCoworker.name} + ${remainingCoworkerCount} till`;
        }

        if (typeof(firstCoworker) === 'undefined') {
            return null;
        }

        else {
            return firstCoworker.name;
        }
    };

    renderStatus = (text, record, index) => {
        const {status} = record;
            
        if(status === 2){
            return <FormattedMessage id={"assignmentStarted"}/>;
        }

        return <FormattedMessage id={"assignmentNotStarted"}/>;
    };

    renderCoworkerShopStatus = (coworker, shop) => {
        const {selectedAssignment} =  this.props;
        if(!selectedAssignment)
            return;

        const {checkinStatus} =  selectedAssignment;
        if(!checkinStatus || checkinStatus.length === 0)
            return (<div className={showListItemStyles.value}>
                <div className={showListItemStyles.notCheckedIn}/>
            </div>);
        
        let coworkerShopCheckin = checkinStatus.find(cs => cs.coworkerId === coworker.key && cs.shop_id === shop.shop_id);
        if(!coworkerShopCheckin)
            return (<div className={showListItemStyles.value}>
                <div className={showListItemStyles.notCheckedIn}/>
            </div>);

        return (<div className={showListItemStyles.value}>
            <div className={showListItemStyles.checkedIn}/>
        </div>);
    };

    renderShopLocation = (shop) => {
        if (shop.latitude === 0 || shop.longitude === 0) {
            return null;
        }

        return (
     <Row key={`${shop.shop_name}`} className={showListItemStyles.row}>
        <div className={showListItemStyles.mapContainer}>
            <div className={showListItemStyles.map}>
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{
                        key: "AIzaSyAlXVX0E8jltlpkArULbk-j_B97iqvPRFM",
                        language: 'sv'
                    }}
                    center={{
                        lat: shop.latitude,
                        lng: shop.longitude
                    }}
                    defaultZoom={16}
                >
                    <Icon type="environment" height="100px" lat={shop.latitude} lng={shop.longitude}/>
                </GoogleMapReact>
            </div>
            <Row className={showListItemStyles.row}>
                <div className={showListItemStyles.shopNameMap}>{shop.shop_name}</div>
            </Row>
        </div>
        </Row>
        )
    };

    renderToggleShopLocation = (shop, i) => {
        if (shop.latitude === 0 || shop.longitude === 0) {
            return null;
        }

        const { mapsToShowIndexes } = this.state;
        const showMaps = mapsToShowIndexes.includes(i);

        return (
            <Row key={`${i} ${shop.shop_name}`} className={showListItemStyles.row}>
                <div className={`${showListItemStyles.shopNameMap} ${showListItemStyles.toggleMapButton}`}  onClick={() => this.handleToggleMap(i) }>
                    <span >{!showMaps &&  `+  `}</span>
                    <span>
                        {!showMaps && shop.shop_name}   
                    </span>
                </div>
                {showMaps && 
                    this.renderShopLocation(shop)
                }
             </Row>
        )
    }

    handleToggleMap = i => {
        const { mapsToShowIndexes } = this.state;
        let uppdatedIndexes = [...mapsToShowIndexes]; 
        if (!uppdatedIndexes.includes(i)) {
            uppdatedIndexes.push(i);
        } else {
            uppdatedIndexes = uppdatedIndexes.filter(e => e !== i)
        }
        this.setState({ mapsToShowIndexes: uppdatedIndexes });
    }

    render() {
        const {assignments, selectedAssignment, onFormPosted, ...remainingProps} = this.props;
        const {accessSuppliers, accessCoWorkers} = this.props;
        const isCoworker = !accessSuppliers && !accessCoWorkers;
        const columns = [{
            title: <FormattedMessage id="start_date"/>,
            dataIndex: 'start_date',
            width: '15%',
            ...this.getColumnSearchProps('start_date'),
            sorter: (a, b) => ('' + a.start_date).localeCompare(b.start_date),
            sortDirections: ['descend', 'ascend'],
        }, {
            title: <FormattedMessage id="shop"/>,
            dataIndex: 'shops',
            width: '40%',
            ...this.getColumnSearchProps('shops'),
            sorter: (a, b) => {
                const [firstShopA] = a.shops;
                const [firstShopB] = b.shops;
                return ('' + firstShopA.shop_name).localeCompare(firstShopB.shop_name)
            },
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => this.renderShopsName(text, record, index)
        }, {
            title: <FormattedMessage id="coWorkers"/>,
            dataIndex: 'coworkers',
            key: 'coworkers',
            width: '30%',
            ...this.getColumnSearchProps('coworkers'),
            sorter: (a, b) => {
                const [firstCoworkerA] = a.coworkers;
                const [firstCoworkerB] = b.coworkers;

                return ('' + firstCoworkerA.name).localeCompare(firstCoworkerB.name)
            },
            sortDirections: ['descend', 'ascend'],
            render: (text, record, index) => this.renderCoworkersName(text, record, index)
        }, {
            title: <FormattedMessage id="status"/>,
            dataIndex: 'status',
            ellipsis: true,
            ...this.getColumnSearchProps('status'),
            render: (text, record, index) => this.renderStatus(text, record, index)
        }];

        let isMapToggle = false; 
        if (selectedAssignment) {
            isMapToggle = selectedAssignment.shops.length >= 10;
        }

        return (
            <div>
                <Modal
                    footer={null}
                    title={this.modalTitle()}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                >
                    <WrappedAssignmentForm ref={this.setFormRef} isEditMode={true}
                                           onFormPosted={onFormPosted} closeModal={this.handleCancel}/>
                </Modal>
                <Table rowKey={record => record.assignment_id} columns={columns}
                       dataSource={assignments} {...remainingProps}
                       style={{
                            minWidth: '900px',
                            maxWidth: '100%',
                            tableLayout: 'fixed',
                        }}
                       onRow={(record, rowIndex) => {
                           return {
                               onClick: (event) => {
                                   this.itemSelected(event, record, rowIndex);
                               },       // click row
                               onDoubleClick: (event) => {
                               }, // double click row
                               onContextMenu: (event) => {
                               },  // right button click row
                               onMouseEnter: (event) => {
                               },   // mouse enter row
                               onMouseLeave: (event) => {
                               },   // mouse leave row
                           };
                       }}/>
                {selectedAssignment &&
                <ShowListItem
                    heading={this.renderShopsName("", selectedAssignment)}
                    onEdit={this.showEditButtonIfPossible()}
                    onClose={this.closeModal}
                    onPrevious={this.onPrevious}
                    onNext={this.onNext}
                >
                    <div>
                        <Col span={12}>
                            <Row className={showListItemStyles.row}>
                                <div className={showListItemStyles.label}>Datum</div>
                                <div className={showListItemStyles.value}>
                                    {selectedAssignment.start_date} - {selectedAssignment.end_date}
                                </div>
                            </Row>
                            <Row className={showListItemStyles.row}>
                                <div className={showListItemStyles.label}>Uppdragsbeskrivning</div>
                                <div className={showListItemStyles.value}>
                                    {selectedAssignment.assignment_description}
                                </div>
                            </Row>

                            <Row className={showListItemStyles.row}>
                                <div className={showListItemStyles.label}>Medarbetare</div>
                            </Row>

                            <Row className={showListItemStyles.row}>
                                {/*First column should always be for the offset, this seemed easiest*/}
                                <Col span={6}>
                                    <div className={showListItemStyles.value}><FormattedMessage id="shops"/></div>
                                </Col>
                                {selectedAssignment.coworkers.map((coworker, i) => (
                                    <Col key={`${i} ${coworker.name}`} span={6}>
                                        <div className={showListItemStyles.value}>{coworker.name}</div>
                                    </Col>
                                ))}
                            </Row>
                            {selectedAssignment.shops.map((shop, i) => (
                                <Row key={`${i} ${shop.shop_name}`}>
                                    <Col span={6}>
                                        <div className={showListItemStyles.value}>{shop.shop_name}</div>
                                    </Col>
                                    {selectedAssignment.coworkers.map((coworker, i) => (
                                        <Col key={`${i}`} span={6}>
                                            {this.renderCoworkerShopStatus(coworker, shop)}
                                            
                                        </Col>
                                    ))}
                                </Row>
                            ))}

                        </Col>
                        <Col span={12}>
                            {isCoworker &&
                            <Row className={showListItemStyles.row}>
                                <div className={showListItemStyles.value}>
                                    <a href={"/assignment-pass/" + selectedAssignment.assignment_id} target="_blank">Visa
                                        besökskort</a></div>
                            </Row>
                            }
                            <Row className={showListItemStyles.row}>
                                <div className={showListItemStyles.value}>{this.renderStatusSection()}</div>
                            </Row>
                            <Row className={showListItemStyles.row}>
                                <div className={showListItemStyles.label}><FormattedMessage id="place"/></div>
                            </Row>
                            {selectedAssignment.shops.map((shop, i) =>
                                <Fragment key={i}>
                                    {isMapToggle 
                                        ? this.renderToggleShopLocation(shop, i)
                                        : this.renderShopLocation(shop)}
                                </Fragment>
                            )}
                        </Col>
                    </div>
                </ShowListItem>
                }
            </div>
        );
    }
}

const mapStateToProps = ({supplier, assignment, auth}) => ({
    selectedSupplier: supplier.supplierPage.selectedSupplier,
    suppliers: supplier.suppliers,
    assignments: assignment.assignments,
    selectedAssignment: assignment.selectedAssignment,
    accessSuppliers: auth.accessSuppliers,
    accessCoWorkers: auth.accessCoWorkers,
});

const mapDispatchToActions = {
    onAssignmentRetrieved: assignmentRetrieved,
    onAssignmentSelected: assignmentSelected,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(AssignmentTable);