import {LOGIN_ACTION_TYPE} from './../actions/constants'

let user = JSON.parse(sessionStorage.getItem('user'));
let jwt = JSON.parse(sessionStorage.getItem('jwt'));

const initialState = {
    user: user,
    jwt: jwt,
    credentials: {
        userId: '',
        password: '',
        rememberMe: false,
    },
    accessSuppliers: getAccessSuppliers(),
    accessCoWorkers: getAccessCoWorkers(),
    finalizeAccountInfo: null,
    isLoggedIn: jwt != null && jwt != "",
    isLoggingIn: false,
    current_user_company_id: user ? user.company_id : null
};

function getAccessSuppliers() {
    if (user == null || user == "")
        return false;

    return user.user_type_text === "Administrator"; //Admin
}

function getAccessCoWorkers() {
    if (user == null || user == "")
        return false;

    return user.user_type_text === "Administrator" || user.user_type_text === "Supplier"; //Admin or Supplier
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_ACTION_TYPE.LOGIN_INITIATED: {
            const {userId, password} = action.payload.loginDetails;
            return {...state, userId, password, isLoggingIn: true};
        }
        case LOGIN_ACTION_TYPE.LOGIN_SUCCESSFUL: {
            const {user, jwt} = action.payload;

            const accessSuppliers = user.user_type_text === "Administrator";
            const accessCoWorkers = user.user_type_text === "Administrator" || user.user_type_text === "Supplier";
            const current_user_company_id = user.company_id; // Extract company_id

            sessionStorage.setItem('jwt', JSON.stringify(jwt));
            sessionStorage.setItem('user', JSON.stringify(user));

            return {
                ...state,
                password: '',
                user,
                jwt,
                isLoggedIn: true,
                isLoggingIn: false,
                accessSuppliers,
                accessCoWorkers,
                current_user_company_id
            };
        }
        case LOGIN_ACTION_TYPE.FINALIZE_ACCOUNT_INFO_RETRIEVED: {
            const {finalizeAccountInfo} = action.payload;

            return {...state, finalizeAccountInfo};
        }
        case LOGIN_ACTION_TYPE.LOGOUT_SUCCESSFUL: {
            sessionStorage.removeItem("jwt");
            sessionStorage.removeItem("user");

            return {
                ...initialState,
                credentials: {
                    userId: '',
                    password: '',
                    rememberMe: false,
                },
                isLoggedIn: false,
                jwt: null,
                user: null,
                current_user_company_id: null
            };
        }
        default:
            break;
    }
    return state;
};

export default authReducer;