import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Modal} from 'antd';
import {Container, Row, Col} from 'react-bootstrap';
import {ReactSVG} from 'react-svg';
//firebase and firebase auth for resetting password
import {getAuth, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";

import SetPasswordForm from "./set-password-form";
import styles from './ResetAccountPage.module.scss';
import './../../actions/'
import {apiCallFinished, apiCallStarted, errorReceived, loginSuccessful} from "../../actions";
import {Redirect} from 'react-router-dom';
import UserFeedback from "../../components/user-feedback";
import { validatePassword, handleValidationErrors } from '../../utils/passwordValidation';

const WrappedSetPasswordForm = Form.create({name: 'setPasswordForm'})(SetPasswordForm);

class SetPasswordPage extends Component {
    state = {
        redirect: false,
        passwordSet: false,
        showModal: false,
        oobCode:null,//storing firebase reset token
        passwordErrors:[]
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        const {userId, code} = this.props.match.params;
        const urlParams = new URLSearchParams(this.props.location.search);
        const oobCode = urlParams.get('oobCode');
        if (oobCode) {
            this.setState({ oobCode });
            const auth = getAuth();
            verifyPasswordResetCode(auth, oobCode)
            .then(email=>{
                this.setState({email});
            }).catch(error => {
                this.props.onErrorReceived(error);
                this.setState({redirect:true});
            });
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {userId, code} = this.props.match.params;
        let {password, confirmPassword} = this.form.getFieldsValue();
        const { oobCode } = this.state;

        try {
            //sequential handling of errors
            this.props.onApiCallStarted();
            //password field validation
            const errors = validatePassword(password);
            if (handleValidationErrors(errors, this.props.onErrorReceived)) {
                return;
            }
            //comparing password and confirmPassword fields
            if (password !== confirmPassword) {
                errors.push('passwordMismatch');
            }
            if (handleValidationErrors(errors, this.props.onErrorReceived)) {
                return;
            }
            const auth = getAuth();
            
            //validate input before submit, need confirm field to work

            await confirmPasswordReset(auth, oobCode, password);
            this.props.onApiCallFinished();

            this.setState({
                passwordSet: true,
                showModal: true
            })
        } catch (e) {
            this.props.onErrorReceived(e)
        }
    }

    setFormRef = (form) => {
        this.form = form;
    };

    renderContent = () => {
        const component = this;
        if(this.state.showModal){
            Modal.info({
                title: 'Klart',
                content: 'Du kan nu logga in med ditt nya lösenord. Du kommer nu att skickas till inloggningen.',
                okText: "Ok",
                onOk() {
                    component.setState({
                        redirect: true,
                        showModal: false
                    })
                },
            });
        }
        
        if (this.state.passwordSet) {
            return <div>Du kan nu logga in med ditt nya lösenord.</div>
        } else {
            const {credentials} = this.props.auth;

            return <WrappedSetPasswordForm ref={this.setFormRef} onSubmit={this.handleSubmit}
                                           credentials={credentials} email={this.state.email}/>
        }
    };

    render() {

        return (
            <UserFeedback>
                <Row>
                    <Col xs={8} className="mx-auto">
                        <Row className={styles.head}>
                            <ReactSVG src="/img/logotype.svg" className="m-auto"/>
                        </Row>

                        <Row className={styles.form}>
                            <Col xs={9} className="mx-auto">
                                {this.state.redirect &&
                                <Redirect to='/'/>
                                }

                                {this.renderContent()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </UserFeedback>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToActions = {
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(SetPasswordPage);