import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Modal} from "antd";
import {clearError, errorReceived} from "../../actions/user-feedback";
import {Container} from "react-bootstrap";

class ErrorModal extends Component {
    closeAndClearErrors = () => {
        this.props.onModalClosed();
    };

    displayErrors = () => {
        //Object with keys that contain all the errors
        let {errors} = this.props;
        if(!errors)
            return;

        let formattedErrorMessages = [];
        for (let errorKey in errors) {
            console.log(errorKey)
            try {
                let messageArray = errors[errorKey];
                console.log(messageArray)
                messageArray.map(message => (
                    formattedErrorMessages.push(<p><FormattedMessage id={message}/></p>)        // Push the key on the array
                ));
            } catch (e) {

            }
        }
        
        if(formattedErrorMessages.length === 0){
            return <p><FormattedMessage id="unknownError"/></p>;
        }

        return formattedErrorMessages;
    };

    render() {
        return (
            <Container>
                <Modal
                    title={<FormattedMessage id="somethingWentWrong"/>}
                    visible={true}
                    onOk={this.closeAndClearErrors}
                    onCancel={this.closeAndClearErrors}
                >
                    {this.displayErrors()}
                </Modal>
            </Container>
        );
    }
}


const mapStateToProps = ({userFeedback}) => ({
    callingApi: userFeedback.callingApi,
    errors: userFeedback.errors
});

const mapDispatchToActions = {
    onErrorReceived: errorReceived,
    onModalClosed: clearError
};

export default connect(mapStateToProps, mapDispatchToActions)(ErrorModal);