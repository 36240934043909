import { USER_FEEDBACK_ACTION_TYPE } from "./constants";

export const apiCallStarted = () => ({
  type: USER_FEEDBACK_ACTION_TYPE.API_CALL_STARTED,
  payload: {},
});

export const apiCallFinished = () => ({
  type: USER_FEEDBACK_ACTION_TYPE.API_CALL_FINISHED,
  payload: {},
});

export const errorReceived = (exception) => ({
  type: USER_FEEDBACK_ACTION_TYPE.ERROR_RECEIVED,
  payload: {
    exception,
  },
});

export const clearError = () => ({
  type: USER_FEEDBACK_ACTION_TYPE.ERROR_CLEARED,
  payload: {},
});

export const showNotification = () => ({
  type: USER_FEEDBACK_ACTION_TYPE.SHOW_NOTIFICATION,
  payload: {},
});

export const hideNotification = () => ({
  type: USER_FEEDBACK_ACTION_TYPE.HIDE_NOTIFICATION,
  payload: {},
});
