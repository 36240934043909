import fetch from './../lib/fetch-client'
import Config from './../config'

const conf = new Config();
const request = fetch();

export function getSuppliers() {
    return request({
        method: "GET",
        path: "users/Supplier",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        return body;
    });
}

export function getCompanies() {
    return request({
        method: "GET",
        path: "companies",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        // If body is empty (e.g., null, undefined, or an empty array), return body as is
        if (!body || body.length === 0) {
            return body;
        }

        //remapping since autocomplete makes use of field "text"
        return body.map(company => ({
            ...company,
            text: company.name
        }));
    });
}

export function createCompany(data) {
    const {company_name, identification_level, shops} = data;
    let storeGuids = shops.map(store => store.value);

    return request({
        method: "post",
        path: "company",
        params: {
            company_name,
            identification_level,
            shops: storeGuids,
        }
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function updateCompany(data) {
    const {company_id, company_name, identification_level, shops} = data;

    let storeGuids = shops.map(store => store.value);
    return request({
        method: "PATCH",
        path: "company",
        params: {
            company_id,
            company_name,
            identification_level,
            shops: storeGuids,
        }
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function deleteCompany(company_id) {
    return request({
        method: "DELETE",
        path: "company?id="+company_id,
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}


export function getContactPeople() {
    const token = conf.jwt;
    
    return request({
        method: "GET",
        path: "contactpeople",
        headers: {
            Authorization: token // Send token in the header
        }
    }).then(function ({ status, headers, body }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        return body;
    });
}

export function getIdentificationLevelRequirements() {
    return request({
        method: "GET",
        path: "identificationRequirementLevel",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function createSuppliers(
    {
        first_name, last_name, email,
        company, identification_level, contact_person
    }) {
    {
        return request({
            method: "POST",
            path: "users/Supplier",
            params: {
                first_name,
                last_name,
                email,
                company_id: company,
                identification_level,
                contact_person_id: contact_person
            }
        }).then(function ({status, headers, body}) {
            if (status !== 200) {
                throw new Error(`Invalid status code. Status: ${status}`);
            }
            return body;
        });
    }
}

export function updateSuppliers(
    {
        key, first_name, last_name, cell_phone_number, email,
        company, identification_level, contact_person
    }) {
    return request({
        method: "PATCH",
        path: "users/Supplier",
        params: {
            key,
            first_name,
            last_name,
            cell_phone_number,
            email,
            company_id: company,
            identification_level,
            contacterson_id: contact_person
        },
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        return body;
    });
}

export function deleteSuppliers(supplier_id) {
    return request({
        method: "DELETE",
        path: "users/Supplier?id=" + supplier_id,
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        return body;
    });
}