import {LOGIN_ACTION_TYPE} from './constants'

export const loginInitiated = (loginDetails) => ({
    type: LOGIN_ACTION_TYPE.LOGIN_INITIATED,
    payload: {
        loginDetails
    }
});

export const loginSuccessful = ({user, jwt}) => ({
    type: LOGIN_ACTION_TYPE.LOGIN_SUCCESSFUL,
    payload: {
        user,
        jwt,
        current_user_company_id: user.company_id
    }
});

export const finalizeAccountInfoRetrieved = (finalizeAccountInfo) => ({
    type: LOGIN_ACTION_TYPE.FINALIZE_ACCOUNT_INFO_RETRIEVED,
    payload: {
        finalizeAccountInfo
    }
});

export const logOutSuccessful = () => ({
    type: LOGIN_ACTION_TYPE.LOGOUT_SUCCESSFUL,
    payload: {}
});