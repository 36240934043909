import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

export default class PageHead extends PureComponent {
    static propTypes = {
        headingId: PropTypes.string.isRequired,
        actionId: PropTypes.string.isRequired,
        onActionClick: PropTypes.func.isRequired,
        showAddButton: PropTypes.bool
    };

    render() {
        const { headingId, actionId, onActionClick, showAddButton } = this.props;

        return (
            <div className="page-head">
                <h1>
                    <FormattedMessage id={headingId} />
                </h1>

                {showAddButton &&
                    <Button size="large" type="primary" onClick={onActionClick}>
                        <FormattedMessage id={actionId}/>
                    </Button>
                }
            </div>
        );
    }
}